import React, {useContext, useEffect, useState} from 'react';
import {Backdrop, Card, CircularProgress, makeStyles} from "@material-ui/core";
import {Loop} from '@material-ui/icons';
import {Actions} from "../../config/wizard.context.config";
import {WizardContext} from "../../contexts/WizardContext";
import {LocaleContext} from "../../contexts/LocaleContext";
import {Layout} from "../layouts/Layout";
import {fetchLatLong} from "../../validation";

const useStyles = makeStyles(theme => ({
  mapBox: {
    width: '100%',
    height: '450px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  loadingText: {
    marginTop: '16px',
    padding: '8px 16px',
    textAlign: 'center'
  }
}));

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export const GoogleMapStep = ({ vertical }) => {
  const {country_iso_code: country, translations: { google_map: {title}}} = useContext(LocaleContext);
  const [{data: {postal}}, dispatch] = useContext(WizardContext);
  const [zoom, setZoom] = useState(3);
  const [open, setOpen] = useState(false);
  const [map, setMap] = useState(undefined);

  useEffect(() => zoomIn(), [map]);
  useEffect(() => zoomInUntil(), [zoom]);
  useEffect(() => {
    let remove = () => undefined;
    (async () => {
      const position = await fetchLatLong({postal, country});
      if (!position) return moveNextStep();
      remove = displayGoogleMap(position);
      setTimeout(moveNextStep, 5000);
    })();
    return () => remove();
  }, []);

  const zoomIn = () => setZoom(zoom + 1);
  const zoomInUntil = () => {
    if (map && zoom <= 12) {
      map.setZoom(zoom);
      setTimeout(zoomIn, 500)
    }
  };
  const displayGoogleMap = (position) => {
    const script = window.document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&callback=initGhMap`;
    script.async = true;
    script.defer = true;
    script.id = 'ghGoogleMapScript';
    window.initGhMap = () => {
      const options = {
        zoom,
        center: position,
        disableDefaultUI: true
      };
      const mapInternal = new window.google.maps.Map(document.getElementById('ghMap'), options);
      setMap(mapInternal);
      new window.google.maps.Circle({
        map: mapInternal,
        radius: 4000,
        fillColor: '#2196f3',
        strokeOpacity: 0.8,
        strokeWeight: 0.1,
        center: position
      });
    };
    window.document.body.appendChild(script);

    return () => {
      const script = window.document.getElementById('ghGoogleMapScript');
      if (script && script.remove) script.remove();
      window.initGhMap = undefined;
    };
  };
  const moveNextStep = () => {
    setOpen(false);
    dispatch({type: Actions.ON_COMPLETE_GOOGLE_MAP});
  };

  const classes = useStyles();
  return (
    <Layout
      title={title}
      icon={<Loop color="inherit"/>}
      vertical={vertical}
    >
      <Card id="ghMap" className={classes.mapBox} />
      <Backdrop className={classes.backdrop} open={open} timeout={5000}>
        <CircularProgress color="inherit"/>
      </Backdrop>
    </Layout>
  );
};

