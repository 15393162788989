import * as yup from "yup";
import React, {useContext} from "react";
import {FormHelperText} from "@material-ui/core";
import {requestCode} from "../verification";
import {WizardContext} from "../contexts/WizardContext";
import {LocaleContext} from "../contexts/LocaleContext";
import {DialogSelect} from "./core/DialogSelect";
import {OutlinedButton} from "./core/OutlinedButton";
import {Form} from "./core/Form";
import PhoneRoundedIcon from "@material-ui/icons/PhoneRounded";
import {run} from "../validation";
import {Field} from "react-final-form";
import {Actions} from "../config/wizard.context.config";
import {DialogTextField} from "./core/DialogTextField";
import * as R from "ramda";

export const RequestCode = ({sent, setSent}) => {
  const [{crossSelling, data: {phoneNumber: phoneFromData}}, dispatch] = useContext(WizardContext);
  const phoneNumber = crossSelling ? R.path(['personal', 'phoneNumber'], crossSelling) : phoneFromData;
  const {
    numbering_plan, sessionId, country_iso_code: country, translations: {
      contact: {phone_number},
      verification: {send_code_by, text, call, send_verification_button, request_code_error, update_button}
    }
  } = useContext(LocaleContext);
  const schema = {
    phoneNumber: run(yup.string().required().phone().label(phone_number)),
    codeType: run(yup.string().required().label(send_code_by))
  };

  return (
    <Form
      initialValues={{phoneNumber: phoneNumber, codeType: "text"}}
      onSubmit={async response => {
        const {phoneNumber, codeType} = response;
        if (crossSelling) dispatch({type: Actions.UPDATE_CROSS_SELLING_PHONE_NUMBER, payload: {phoneNumber, codeType}});
        else dispatch({type: Actions.UPDATE_PHONE_NUMBER, data: {phoneNumber}});
        const {sent} = await requestCode({sessionId, phone: phoneNumber, type: codeType, country});
        setSent(sent);
        return sent;
      }}
      isStepSubmitted={false}
      render={({submitting, form: {change}}) => {
        return (
          <>
            <Field
              name="phoneNumber"
              validate={schema.phoneNumber}
              render={({input, meta: {active, touched, error}}) => (
                <DialogTextField
                  label={phone_number}
                  input={input}
                  meta={{active, touched, error}}
                  change={change}
                  validate={schema.phoneNumber}
                  format={numbering_plan.format}
                  AdornmentIcon={PhoneRoundedIcon}
                  labelButton={update_button}
                  autoComplete="tel-national"
                  autoFocus
                />
              )}
            />

            <Field
              name="codeType"
              validate={schema.codeType}
              render={({input, meta: {active, touched, error}}) => (
                <DialogSelect
                  label={send_code_by}
                  change={change}
                  input={input}
                  meta={{active, touched, error}}
                  options={[
                    {id: "text", label: text},
                    {id: "call", label: call},
                  ]}
                />
              )}
            />

            {sent === false && <FormHelperText error> {request_code_error} </FormHelperText>}
            <OutlinedButton disabled={submitting} label={send_verification_button}/>
          </>
        )
      }}
    />
  )
};