let message = {};

export const initializeMessage = ({tracking}) => {
  if (tracking) {
    message.clientRoute = tracking.clientRoute;
    message.referer = tracking.referer;
    message.search = tracking.search;
  } else {
    message.clientRoute = window.location.href;
    message.referer = document.referrer;
    message.search = window.location.search
  }
}

export const initializeHeightListener = ({containerId}) => {
  let height;
  const sendPostMessage = () => {
    if (height !== document.getElementById(containerId).offsetHeight) {
      height = document.getElementById(containerId).offsetHeight;
      postMessage({ action: 'UPDATE_HEIGHT', frameHeight: height });
    }
  };
  window.onload = () => {
    const callback = () => { sendPostMessage(); setTimeout(callback, 500); };
    callback();
  };
  window.onresize = () => sendPostMessage();
  document.getElementById(containerId).onmouseover = () => sendPostMessage();
}

export const findClientRoute = () => message.clientRoute;
export const findReferer = () => message.referer;
export const findLocationSearch = () => message.search;

export const postMessage = (payload) => {
  // Reference: https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
  window.parent.postMessage(payload, '*');
};