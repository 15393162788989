import {WIZARD_PROXY_API} from "./wizard.constants";
import {debounce} from "./debounce";

export const validatePostal = debounce(async ({postal, country, invalid}) => {
  try {
    if (invalid) return true;
    const response = await fetch(`${WIZARD_PROXY_API}/validation/validate-postal?postal=${postal}&country=${country}`);
    const result = await response.json();
    return result && result.valid;
  } catch (e) {
    console.error(e);
    return false;
  }
});

export const validateEmail = debounce(async ({email, invalid}) => {
  try {
    const SOURCE = process.env.REACT_APP_SOURCE;
    if (invalid) return true;
    const response = await fetch(`${WIZARD_PROXY_API}/validation/validate-email?email=${email}&source=${SOURCE}`);
    const {valid, did_you_mean} = await response.json();
    // return result && result.valid;
    return {valid, did_you_mean};
  } catch (e) {
    console.error(e);
    return false;
  }
});

export const run = schema => async (values) => {
  try {
    await schema.validate(values, {abortEarly: false});
  } catch (e) {
    return e.errors && e.errors[0];
  }
};

export const fetchLatLong = async ({postal, country}) => {
  try {
    const response = await fetch(`${WIZARD_PROXY_API}/validation/validate-postal?postal=${postal}&country=${country}`);
    const result = await response.json();
    if (result && result.valid) return result.geo;
  } catch (e) {
    console.error(e);
    return false;
  }
};