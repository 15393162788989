import React, {useContext, useEffect, useState} from "react";
import * as R from 'ramda';
import * as yup from "yup";
import {Button, FormHelperText} from "@material-ui/core";
import {VpnKey} from "@material-ui/icons";
import {Actions} from "../config/wizard.context.config";
import {verifyCode} from "../verification";
import {submitContact, submitCrossSelling} from "../wizard";
import {WizardContext} from "../contexts/WizardContext";
import {LocaleContext} from "../contexts/LocaleContext";
import {Form} from "./core/Form";
import {run} from "../validation";
import {LabeledInput} from "./core/LabeledInput";
import {OutlinedButton} from "./core/OutlinedButton";

export const VerificationCode = ({setSent}) => {
  const [counter, setCounter] = useState(10);
  const [verified, setVerified] = useState(undefined);
  const [{crossSelling, data: {phoneNumber: phoneFromData}}, dispatch] = useContext(WizardContext);
  const phoneNumber = crossSelling ? R.path(['personal', 'phoneNumber'], crossSelling) : phoneFromData;
  const {
    translations: {
      verification: {verification_code, verify_button, resend_code_button, verification_code_error, verification_code_required, }
    },
    country_iso_code: country,
    sessionId
  } = useContext(LocaleContext);

  useEffect(() => {
    const displaySeconds = setInterval(() => {
      if (counter === 0) {
        clearInterval(displaySeconds);
      }
      setCounter(counter - 1);
    }, 1000);
    return () => {
      clearInterval(displaySeconds);
    }
  });

  return (
    <Form
      onSubmit={async ({verificationCode}) => {
        const valid = await verifyCode({sessionId, phone: phoneNumber, code: verificationCode, country});
        if (valid) dispatch({type: Actions.VERIFY_CODE});
        setVerified(valid);

        if (valid) {
          const requestData = {sessionId, phoneNumber, country};
          if (crossSelling) await submitCrossSelling({...requestData, intentId: crossSelling.intentId});
          else await submitContact(requestData);
        }

        return valid;
      }}
      isStepSubmitted={false}
      render={({handleSubmit, reset, submitting, pristine, valid, values, form: {change}}) => {
        return (
          <>
            <LabeledInput
              validate={run(yup.string().required(verification_code_required).length(6).label(verification_code))}
              name="verificationCode"
              label={verification_code}
              AdornmentIcon={VpnKey}
              autoFocus
            />

            {valid && verified === false && <FormHelperText error>{verification_code_error}</FormHelperText>}

            <OutlinedButton disabled={submitting} label={verify_button}/>

            <Button fullWidth disabled={counter > 0} size="large" variant="outlined" onClick={() => setSent(undefined)}>
              {`${resend_code_button} ${counter > 0 ? `(${counter})` : ''}`}
            </Button>
          </>
        )
      }}
    />
  );
};