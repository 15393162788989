import React from 'react';
import {Divider, Typography, makeStyles } from "@material-ui/core";
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.70em'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '12px'
        }
    }
}));

export const StepHeading = ({heading}) => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="overline" display="block" gutterBottom className={classes.root}>
                {heading}
            </Typography>
            <Divider/>
        </>
    );
}

StepHeading.propTypes = {
  heading: PropTypes.string.isRequired
};