import React, {useContext, useEffect} from 'react';
import {Steps, Actions} from "../config/wizard.context.config";
import {WizardContext} from "../contexts/WizardContext";
import {ThankYouStep} from "./steps/ThankYouStep";
import {ContactStep} from "./steps/ContactStep";
import {QualifyingStep} from "./steps/QualifyingStep";
import {VerificationStep} from "./steps/VerificationStep";
import {GoogleMapStep} from "./steps/GoogleMapStep";

export const StepContainer = ({wizard}) => {

  const [{step}, dispatch] = useContext(WizardContext);
  useEffect(() => {
    dispatch({type: Actions.ON_LOAD, questions: wizard.qualifying_questions});
  }, []);

  const {qualifying_questions, vertical_name, commercial, qualifying_questions_title, vertical_display_name, mock } = wizard;

  const title = `Get ${vertical_display_name} Pricing`

  if (!step) return null;

  switch (step.name) {
    case Steps.QUESTION_POSTAL.name:
    case Steps.QUESTION.name:
      return <QualifyingStep questions={qualifying_questions} qualifyingQuestionsTitle={qualifying_questions_title} vertical={vertical_name} title={title}/>;
    case Steps.GOOGLE_MAP.name:
      return <GoogleMapStep vertical={vertical_name}/>;
    case Steps.CONTACT.name:
      return <ContactStep vertical={vertical_name} questions={qualifying_questions} commercial={commercial} mock={mock}/>;
    case Steps.VERIFICATION_STEP.name:
      return <VerificationStep vertical={vertical_name}/>;
    default:
      return <ThankYouStep vertical={vertical_name} title={qualifying_questions_title} commercial={commercial} />
  }
};