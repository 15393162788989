import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import formatString from "format-string-by-pattern";
import {CircularProgress, FormControl, InputAdornment, InputLabel, makeStyles, OutlinedInput,} from "@material-ui/core";
import {ErrorMessage} from "./ErrorMessage";
import {ValidatingField} from "./ValidatingField";
import {WizardContext} from "../../contexts/WizardContext";
import {Actions} from "../../config/wizard.context.config";
import {HelperMessage} from "./HelperMessage";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {green, red} from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '12px',
    position: 'relative'
  },
  label: {
      fontSize: '20px',
      backgroundColor: 'white',
      paddingLeft: '4px',
      paddingRight: '4px',
      marginLeft: theme.spacing(4)
  },
  input: {
    fontSize: theme.spacing(3),
      padding: theme.spacing(2, 6),
    overflow: "auto",
  }
}));

export const LabeledInput = ({
  name,
  label,
  validate,
  format,
  mode,
  AdornmentIcon,
  entered,
  isAsync,
  isBigIcon,
  helperText,
  ...initialProps
}) => {
  const classes = useStyles();
  const [_, dispatch] = useContext(WizardContext);
  return (
    <ValidatingField
      name={name}
      parse={format ? formatString(format) : undefined}
      placeholder={format || undefined}
      validate={validate || undefined}
      render={({input, meta}) => {
        const {active, touched, error, validating} = meta; // , dirty
        const errorCondition = (touched || entered); //  || (isAsync && dirty)
        const hasError = errorCondition && !!error;
        const EndAdornment = isAsync ? (
            validating ? <CircularProgress size={25}/> :
              hasError ? <CancelIcon style={{ color: red[400] }} fontSize={isBigIcon ? "large" : "small"} /> :
                errorCondition && <CheckCircleIcon style={{ color: green[500]}} fontSize={isBigIcon ? "large" : "small"} />
          ) :
          (
            hasError ? <CancelIcon style={{ color: red[400] }} fontSize="small" /> :
              errorCondition && <CheckCircleIcon style={{ color: green[500]}} fontSize="small" />
          );
        const { onClick } = {...input, ...initialProps};
        return (
          <FormControl fullWidth variant="outlined" className={classes.root}>
            <InputLabel className={classes.label} error={hasError}>{label}</InputLabel>
            <OutlinedInput
              {...input}
              {...initialProps}
              onClick={event => {
                dispatch({type: Actions.OPEN_WIZARD_POPUP});
                return onClick && onClick(event);
              }}
              error={hasError}
              inputProps={mode ? {inputMode: mode} : {}}
              classes={{
                  input: classes.input
              }}
            />
              <div style={{ color: hasError ? red["400"] : 'inherit', display: 'inline-block', position: 'absolute', top: '16px', left: '12px'}}>
                  <AdornmentIcon/>
              </div>
              <div style={{ display: 'inline-block', position: 'absolute', right: '16px', top: '24%' }}>
                  {EndAdornment}
              </div>
            <ErrorMessage touched={errorCondition} error={error} keepSpace={isAsync && isBigIcon} />
            <HelperMessage touched={errorCondition} error={error} text={helperText} />
          </FormControl>
        )
      }}
    />
  )
};

LabeledInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validate: PropTypes.func.isRequired,
  format: PropTypes.string,
  mode: PropTypes.string,
  AdornmentIcon: PropTypes.object.isRequired,
  entered: PropTypes.bool,
};