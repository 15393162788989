import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const useStylesPopup = makeStyles((theme) => ({
  backdrop: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.05)'
  },
  listItemTextPrimary: {
    [theme.breakpoints.down('sm')] : {
      fontSize: '16px'
    },
    [theme.breakpoints.up('md')] : {
      fontSize: '20px'
    }
  }
}));

export const InputDialog = ({label, secondary: secondaryInitial, instant, classes, render, questionNumber}) => {
  const classesPopup = useStylesPopup();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [secondary, setSecondary] = useState(secondaryInitial);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'inherit';
      document.body.style.paddingRight = 'inherit';
    }
  }, [open]);

  return (
      <>
        <ListItem
            divider
            role="listitem"
            alignItems="flex-start"
            className={classes && classes.root}
            onClick={e => setOpen(true)}
        >
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: theme.palette.primary.main }}>
              {questionNumber}
            </Avatar>
          </ListItemAvatar>

          <ListItemText
              primary={label}
              secondary={render({setSecondary, setOpen})}
              classes={{
                primary: classesPopup.listItemTextPrimary
              }}
          />
        </ListItem>
      </>
  )
};

InputDialog.propTypes = {
  label: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
  classes: PropTypes.object,
  render: PropTypes.func.isRequired
};