import {WIZARD_PROXY_API} from "./wizard.constants";
import {useEffect, useState} from "react";

export const submitContact = async (data) => {
  try {
    const response = await fetch(`${WIZARD_PROXY_API}/wizard/contacts`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    });
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const submitCrossSelling = async (payload) => {
  try {
    const response = await fetch(`${WIZARD_PROXY_API}/wizard/cross-selling`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(payload)
    });
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchSchema = async ({pid}) => {
  try {
    const response = await fetch(`${WIZARD_PROXY_API}/wizard/schemas?pid=${pid}`);
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const useImage = () => {
  const [response, setResponse] = useState({});
  useEffect(() => {
    (async () => {
      setResponse(await fetchImage());
    })();
  }, []);
  return response;
};

const fetchImage = async () => {
  try {
    const response = await fetch(`${WIZARD_PROXY_API}/wizard/image`);
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
};