import * as yup from "yup";
import {validateEmail, validatePostal} from "../validation";

export const registerYupConfig = ({
  locale: {numbering_plan, postal, country_iso_code},
  validation: {required, email, min, max, length, integer, letter_included, phone_number, postal: postalValidationMessage, postal_async, email_async},
}) => {

  yup.addMethod(yup.string, "letterIncluded", function () {
    const makeMessage = label => letter_included.replace(/\${\s*label\s*}/g, label);
    return this.test("letterIncluded", ({label}) => makeMessage(label), async (value) => !value || !/^[\d\W_]+$/.test(value.trim()));
  });

  yup.addMethod(yup.string, "phone", function () {
    const pattern = new RegExp(numbering_plan.format.replace(/[0-9]/g, "[0-9]").replace(/\(/g, "\\(").replace(/\)/g, "\\)"));
    return this.test("phone", ({label}) => `${phone_number} (ex - ${numbering_plan.format})`, value => !value || pattern.test(value));
  });

  yup.addMethod(yup.string, "postal", function () {
    const patternPart = postal.format.replace(/[0-9]/g, "[0-9]").replace(/[a-zA-Z]/g, "[a-zA-Z]");
    const pattern = new RegExp(`^${patternPart}$`);
    const makeMessage = label => postalValidationMessage.replace(/\${\s*label\s*}/g, label);
    return this.test("postal", ({label}) => makeMessage(label), value => !value || pattern.test(value));
  });

  yup.addMethod(yup.string, "postalAsync", function () {
    const makeMessage = label => postal_async.replace(/\${\s*label\s*}/g, label);
    return this.test("postalAsync", ({label}) => makeMessage(label), async (value) => {
      if (!value) return true;
      return await validatePostal({postal: value, country: country_iso_code, invalid: value.length < postal.format.length });
    });
  });

  yup.addMethod(yup.string, "emailAsync", function () {
    let message = () => undefined;
    return this.test("emailAsync", () => message(), memoize(async (value) => {
      if (!value) return true;
      const {valid, did_you_mean} = await validateEmail({ email: value, invalid: !await yup.string().email().isValid(value) });
      message = () => did_you_mean ? email_async.replace(/\${\s*value\s*}/g, did_you_mean) : email;
      return valid;
    }));
  });

  yup.setLocale({mixed: {required}, string: {email, min, max, length}, number: {integer}});
};

const memoize = fn => {
  let lastArg;
  let lastResult;
  return arg => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};
