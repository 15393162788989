import React from 'react';
import {FormHelperText} from "@material-ui/core";
import PropTypes from 'prop-types';

export const ErrorMessage = ({touched, error, keepSpace}) =>
  keepSpace ? <FormHelperText error style={{ marginLeft: '2px'}}>{touched && error}</FormHelperText> :
  touched && error ? (
    <FormHelperText error style={{ marginLeft: '2px'}}>{error}</FormHelperText>
  ) : null;

ErrorMessage.propTypes = {
  error: PropTypes.string,
};