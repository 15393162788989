import {Form as FinalForm} from "react-final-form";
import createDecorator from 'final-form-focus';
import React, {useContext} from "react";
import {WizardContext} from "../../contexts/WizardContext";

export const focusOnErrors = createDecorator();
export const Form = ({
 initialValues,
 className,
 onSubmit,
 render,
 action,
 isStepSubmitted = true
}) => {
  const [state, dispatch] = useContext(WizardContext);
  const updateDataBeforeSubmit = async data => {
    const result = onSubmit && await onSubmit({data: {...state.data, ...data}});
    if (result !== false) dispatch({type: action || state.step.action, data});
  };
  return (
    <FinalForm
      initialValues={initialValues || state.data}
      decorators={[focusOnErrors]}
      onSubmit={isStepSubmitted ? updateDataBeforeSubmit : onSubmit}
      render={(formProps) => {
        const {handleSubmit, reset, submitting, pristine, valid, values, form} = formProps;
        return (
          <form onSubmit={handleSubmit} className={className}>
            {render({handleSubmit, reset, submitting, pristine, valid, values, form})}
          </form>
        )
      }}
    />
  )
};