import React, {useContext, useEffect, useState} from 'react';
import * as yup from "yup";
import supportsWebP from 'supports-webp';
import {
  Card,
  CardContent,
  CardMedia,
  Dialog,
  makeStyles,
  Typography,
  useTheme,
  Grid,
  IconButton
} from "@material-ui/core";
import {LocationOn} from "@material-ui/icons";
import {ReCaptchaV3Score} from "../boundaries/recaptcha.v3.score";
import {RE_CAPTCHA_V3_SITE_KEY} from "../wizard.constants";
import {run} from "../validation";
import {Actions} from "../config/wizard.context.config";
import {WizardContext} from "../contexts/WizardContext";
import {LocaleContext} from "../contexts/LocaleContext";
import {OutlinedButton} from "./core/OutlinedButton";
import {LabeledInput} from "./core/LabeledInput";
import {Form} from "./core/Form";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import {UserAvatar} from "./UserAvatar";
import {postMessage} from "../message";

const MAX_WIDTH_550 = '(max-width:500px)'

const useStylesPopup = makeStyles((theme) => ({
  backdrop: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.05)'
  },
  dialogContainer: {
    [theme.breakpoints.up('md')]: {
      width: '440px',
      margin: '0 auto',
    }
  },
  dialogPaperFullScreen: {
    height: 'inherit',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
    }
  }
}));

export const Postal = ({ vertical, title }) => {
  const [state, dispatch] = useContext(WizardContext);
  const [image, setImage] = useState(undefined);
  const classesPopup = useStylesPopup();
  const fullScreen = useMediaQuery(MAX_WIDTH_550);
  const {postalOpen: open} = state;

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'inherit';
      document.body.style.paddingRight = 'inherit';
    }
    (async () => {
      const extension = await supportsWebP ? 'webp' : 'jpg';
      setImage(`${process.env.REACT_APP_SPLASH_IMAGE_URL}/verticals/${vertical}.${extension}`);
    })()
  }, [open]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      maxWidth="xs"
      disablePortal
      disableEnforceFocus
      BackdropProps={{classes: {root: classesPopup.backdrop}}}
      style={{position: 'absolute' }}
      classes={{
        paper: classesPopup.dialogContainer,
        paperFullScreen: classesPopup.dialogPaperFullScreen
      }}
    >
      <PostalCard vertical={vertical} title={title} image={image} />
    </Dialog>
  )
};

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: '12px'
  },
  button: {
    marginTop: '12px'
  },
  cardMedia: {
    height: '200px',
    backgroundPosition: 'top'
  },
  inputIcon: {
    color: theme.palette.grey["800"]
  },
  titleLong: {
    textTransform: 'capitalize',
    fontSize: '1em!important',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9em!important',
    }
  },
  title: {
    textTransform: 'capitalize',
    fontSize: '1em!important',
  },
  subTitle: {
    [theme.breakpoints.down('sm')] : {
      fontSize: '0.875em'
    },
    [theme.breakpoints.up('md')] : {
      fontSize: '16px'
    }
  },
  avatarGrid: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  postalGrid: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'inherit'
    }
  }
}));
const PostalCard = ({ vertical, title, image }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(MAX_WIDTH_550);
  const {translations: {postal: t_postal}, postal: {format, type}, options = {}} = useContext(LocaleContext);
  const {label, sub_title, submit_button, helper_text} = t_postal;
  const classes = useStyles();
  const displaySplashImage = options.hasOwnProperty('splashImage') ? options.splashImage : true;
  return (
      <Card square={fullScreen} elevation={fullScreen ? 0 : 1}>
        {displaySplashImage &&
          <CardMedia
            className={classes.cardMedia}
            image={image}
            title={vertical}
          />
        }
        <CardContent>
          <Grid container spacing={2} alignItems="center" className={classes.postalGrid}>
            <Grid item className={classes.avatarGrid}>
              {
                fullScreen ?
                    (
                        <UserAvatar />
                    ) : (
                        <IconButton edge="start" style={{ color: 'white', backgroundColor:  theme.palette.secondary.main }} size='small'>
                          <DoubleArrowIcon/>
                        </IconButton>
                    )
              }
            </Grid>
            <Grid item>
              <Typography className={ title.length > 30 ? classes.titleLong : classes.title} variant={"h6"}>{title}</Typography>
              <Typography color="textSecondary" className={classes.subTitle}>
                {sub_title}
              </Typography>
            </Grid>
          </Grid>
          <Form
            action={Actions.SUBMIT_POSTAL}
            onSubmit={() => {
              postMessage({ eventName: 'ghFirstInteraction' });
              ReCaptchaV3Score.init({siteKey: RE_CAPTCHA_V3_SITE_KEY, pid: options.pid});
            }}
            className={classes.margin}
            render={({submitting}) => (
              <>
                <LabeledInput
                  name="postal"
                  label={label}
                  validate={run(yup.string().required().postal().label(label))}
                  format={format}
                  mode={type}
                  type={type === 'numeric' ? 'number': 'text'}
                  AdornmentIcon={LocationOn}
                  autoComplete="postal-code"
                  helperText={helper_text}
                />
                <OutlinedButton disabled={submitting} label={submit_button} color="primary"/>
              </>
            )}
          />
        </CardContent>
      </Card>
  )
};
