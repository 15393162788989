import React, {useState} from "react";
import { Field } from "react-final-form";

export const ValidatingField = (props) => {
  const [validating, setValidating] = useState(false);
  return (
  <Field
      {...props}
      validate={props.validate ? async (...args) => {
        setValidating(true);
        const result = await props.validate(...args);
        setValidating(false);
        return result;
      } : undefined}
      render={renderProps => {
        const render = props.render || props.children;
        if (!render) return null;

        const meta = {...renderProps.meta, validating};
        return props.render({...renderProps, meta});
      }}
    />
  );
};