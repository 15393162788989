import {WIZARD_PROXY_API} from "./wizard.constants";

export const requestCode = async ({sessionId, phone, type = '', country = ''}) => {
  try {
    const response = await fetch(`${WIZARD_PROXY_API}/verification/request-code?trackingId=${sessionId}&phone=${encodeURIComponent(phone)}&type=${type}&country=${country}`);
    const result = await response.json();
    return {
      sent: result.sent,
      type: result.type,
    };
  } catch (e) {
    console.error(e);
    return {
      sent: false
    };
  }
};

export const verifyCode = async ({sessionId, phone, code, country = ''}) => {
  try {
    const response = await fetch(`${WIZARD_PROXY_API}/verification/verify-code?trackingId=${sessionId}&phone=${encodeURIComponent(phone)}&code=${code}&country=${country}`);
    const result = await response.json();
    return result && result.valid;
  } catch (e) {
    console.error(e);
    return false;
  }
};