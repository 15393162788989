import React, {useContext} from "react";
import * as R from 'ramda';
import * as yup from "yup";
import {Field} from "react-final-form";
import {List, makeStyles} from "@material-ui/core";
import {Stars} from '@material-ui/icons';
import {run} from "../../validation";
import {LocaleContext} from "../../contexts/LocaleContext";
import {Actions} from "../../config/wizard.context.config";
import {WizardContext} from "../../contexts/WizardContext";
import {DialogSelect} from "../core/DialogSelect";
import {OutlinedButton} from "../core/OutlinedButton";
import {Form} from "../core/Form";
import {Layout} from "../layouts/Layout";
import {StepHeading} from "../layouts/StepHeading";
import {Postal} from "../Postal";
import {captureException} from '@sentry/browser';

const useStyles = makeStyles(theme => ({
    list: {
        position: 'inherit',
    },
    features: {
        backgroundColor: '#eceff1',
        borderRadius: '4px'
    }
}));

export const QualifyingStep = ({questions, vertical, title, qualifyingQuestionsTitle}) => {
  const [{crossSelling, first, step: {action}}, dispatch] = useContext(WizardContext);
  const crossSellingQuestions = R.prop('questions', crossSelling);
  const crossSellingTitle = `Top ${R.prop('verticalDisplayName', crossSelling)} Pricing`;

  const classes = useStyles();
  const {sessionId, translations: {qualifying_question: {sub_title, submit_button}}} = useContext(LocaleContext);

  // TODO: DO NOT DELETE UNTIL business_loans QQ issues are solved.
  try {
    if (!questions) {
      captureException(new Error(`(TEST - NULL QUESTIONS) ${vertical} - ` + JSON.stringify({sessionId, vertical, questions: null, title})));
    } else if (questions.length === 0) {
      captureException(new Error(`(TEST - EMPTY QUESTIONS) ${vertical} - ` + JSON.stringify({sessionId, vertical, questions: questions.length, title})));
    }
  } catch (e) {
    captureException(e);
  }

  return (
    crossSelling ?
      <Layout
        title={crossSellingTitle}
        icon={<Stars color="inherit"/>}
        vertical={vertical}
      >
        <StepHeading heading={sub_title}/>
        <List component="div" role="list" className={classes.list}>
          <Form
            initialValues={{answers: R.prop('answers', crossSelling)}}
            isStepSubmitted={false}
            onSubmit={async ({answers}) => await dispatch({type: Actions.SUBMIT_CROSS_SELLING_QUESTION, payload: {answers}})}
            render={({submitting, form: {change}, values: {answers: qualifications}}) => {
              return createFormContent({submitting, translations: {submit_button}, questions: crossSellingQuestions, change});
            }}
          />
        </List>
      </Layout>
      :
      <Layout
        title={title}
        icon={<Stars color="inherit"/>}
        vertical={vertical}
      >
        <StepHeading heading={sub_title}/>
        <List component="div" role="list" className={classes.list}>
          <Form render={({submitting, form: {change}, values: {answers: qualifications}}) =>
              createFormContent({submitting, translations: {submit_button}, questions, change, onChange: (_, {name, value}) => {
                      const answers = { ...qualifications, [R.last(R.split('.', name))]: value };
                      const fulfilled = first && R.length(R.keys(answers)) === R.length(questions);
                      if (fulfilled) dispatch({type: action, data: {answers}})
                  }})
          }/>
        </List>
        <Postal vertical={vertical} title={qualifyingQuestionsTitle}/>
      </Layout>
  );
};

const createFormContent = ({submitting, questions, change, onChange, translations: {submit_button}}) => (
    <>
        {
            questions.map(({ title, id, answers, question }, index) => (
                    <Field
                        key={id}
                        name={`answers.${id}`}
                        validate={run(yup.string().required().label(title))}
                        render={({input, meta: {active, touched, error}}) => (
                            <DialogSelect
                                questionNumber={index + 1}
                                change={change}
                                onChange={onChange}
                                input={input}
                                meta={{active, touched, error}}
                                label={`Select ${title}`}
                                options={answers}
                            />
                        )}
                    />
                )
            )
        }
        <div style={{paddingTop: `${(4 - questions.length) * 40}px`}}>&nbsp;</div>
        <OutlinedButton disabled={submitting} label={submit_button} color="secondary"/>
    </>
);