export const debounce = (inner, milliseconds = 1000) => {
  let timer = null;
  let resolves = [];
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      const result = inner(...args);
      resolves.forEach(r => r(result));
      resolves = [];
    }, milliseconds);
    return new Promise(resolve => resolves.push(resolve));
  };
};