export const WIZARD_PROXY_API = process.env.REACT_APP_WIZARD_PROXY_API;
export const EXCHANGE_API = process.env.REACT_APP_EXCHANGE_API;
export const REPLAY_API = process.env.REACT_APP_REPLAY_API;
export const RE_CAPTCHA_V3_SITE_KEY = process.env.REACT_APP_RE_CAPTCHA_V3_SITE_KEY;

export const WIZARD_APP = process.env.REACT_APP_WIZARD_APP;

export const WEBSITE_DESIGN_PLACEMENT = process.env.REACT_APP_WEBSITE_DESIGN_PLACEMENT;
export const BUSINESS_LOANS_PLACEMENT = process.env.REACT_APP_BUSINESS_LOANS_PLACEMENT;
export const BUSINESS_PHONE_SYSTEMS_PLACEMENT = process.env.REACT_APP_BUSINESS_PHONE_SYSTEMS_PLACEMENT;
export const GPS_FLEET_TRACKING_PLACEMENT = process.env.REACT_APP_GPS_FLEET_TRACKING_PLACEMENT;
export const CREDIT_CARD_PROCESSING_PLACEMENT = process.env.REACT_APP_CREDIT_CARD_PROCESSING_PLACEMENT;

export const POS_SYSTEMS_PLACEMENT = process.env.REACT_APP_POS_SYSTEMS_PLACEMENT;

export const GH_WEBSITE = process.env.REACT_APP_GH_WEBSITE;

export const GH_CLICKS = process.env.REACT_APP_CLICK_APP;

export const BUSINESS_PHONE_SYSTEMS_PLACEMENT_CLICKS_ID = process.env.REACT_APP_BUSINESS_PHONE_SYSTEMS_PLACEMENT_CLICKS_ID;
export const CREDIT_CARD_PROCESSING_PLACEMENT_CLICKS_ID = process.env.REACT_APP_CREDIT_CARD_PROCESSING_PLACEMENT_CLICKS_ID;
export const DIGITAL_COPIERS_PLACEMENT_CLICKS_ID = process.env.REACT_APP_DIGITAL_COPIERS_PLACEMENT_CLICKS_ID;
export const GPS_FLEET_TRACKING_PLACEMENT_CLICKS_ID = process.env.REACT_APP_GPS_FLEET_TRACKING_PLACEMENT_CLICKS_ID;
export const POS_SYSTEMS_PLACEMENT_CLICKS_ID = process.env.REACT_APP_POS_SYSTEMS_PLACEMENT_CLICKS_ID
export const BUSINESS_LOANS_PLACEMENT_CLICKS_ID = process.env.REACT_APP_BUSINESS_LOANS_PLACEMENT_CLICKS_ID;