import * as R from 'ramda';

export const isCommercial = ({questions, answers, commercial}) =>
  commercial || R.not(R.isEmpty(
      R.intersection(
        R.pipe(
          R.map(R.prop('answers')),
          R.flatten,
          R.filter(R.prop('commercial')),
          R.map(R.prop('id'))
        )(questions),
        R.values(answers)
      )
  ));