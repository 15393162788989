import React, {useContext, useEffect, Fragment } from 'react';
import {AppBar, Box, makeStyles, Toolbar, Typography, useTheme} from "@material-ui/core";
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import isMobile from "is-mobile";
import {WizardContext} from "../../contexts/WizardContext";
import {Actions} from "../../config/wizard.context.config";
import {findClientRoute, findReferer, postMessage} from "../../message";
import {UserAvatar} from "../UserAvatar";
import staxPaymentLogo from "../../images/stax-payment-logo.svg";
import {GH_WEBSITE} from "../../wizard.constants";

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    width: '100%'
  },
  layoutBox: {
    padding: '8px 16px',
  },
  button: {
    marginTop: '12px',
    marginBottom: '12px'
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  title: {
    flex: 1,
    marginLeft: '12px',
    textTransform: 'capitalize',
    fontSize: '18px',
  }
}));

export const Layout = props => {
  const fullScreen = isMobile();
  const [{wizardOpen: open}, dispatch] = useContext(WizardContext);
  const classes = useStyles();
  const {icon, title, vertical, hidePartneredLogo = false } = props;
  const boxClassName = (props.noPadding) ? '' : classes.layoutBox;
  return (
    <LayoutPopup fullScreen={fullScreen}>
      <div className={classes.root} id="gh-wizard-layout">
        <AppBar className={classes.appBar} style={{ position: (fullScreen && open) ? 'relative' : 'static'}}>
          <Toolbar>
            <UserAvatar />
            <Typography variant="h6" className={`${classes.title} gh-wizard-h6`}>
              {title}
            </Typography>
            {(fullScreen && open)
                ? (
                    <IconButton edge="start" color="inherit" onClick={() => dispatch({type: Actions.CLOSE_WIZARD_POPUP})} aria-label="close">
                      <CloseIcon />
                    </IconButton>
                )
                : (<Fragment/>)
            }
          </Toolbar>
        </AppBar>
        <Box className={boxClassName}>
          {props.children}
          <Box display='flex' justifyContent='center' alignItems='center'>
            <a
                href={`${GH_WEBSITE}/?source=${encodeURIComponent(findClientRoute())}&referer=${encodeURIComponent(findReferer())}`}
                target="_blank"
                style={{ textDecoration: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            >
              <Box fontSize={10} color="text.hint" mr={2} fontWeight={600}>POWERED BY</Box>
              <img src={`${process.env.REACT_APP_SPLASH_IMAGE_URL}/gh-gray.svg`} alt="GrowthHub Gray Logo" height={40} width={40}/>
            </a>

            {
                vertical && vertical === 'credit_card_processing' && (
                    <Box display='flex' justifyContent='center' alignItems='center' ml={2}>
                      <Box mx={0.5} fontSize={10} color="text.hint" fontWeight={600}>IN PARTNERSHIP WITH</Box>
                      <img src={staxPaymentLogo} alt='Stax payments' height={40} />
                    </Box>
                )
            }

          </Box>
        </Box>
      </div>
    </LayoutPopup>
  )
};

Layout.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

const LayoutPopup = ({children, fullScreen}) => {
  const [{wizardOpen: open}] = useContext(WizardContext);
  const fullScreenOpen = fullScreen && open;
  useEffect(() => {
    postMessage({ action: 'FULL_SCREEN_OPEN', fullScreenOpen });
  }, [fullScreenOpen]);
  return children;
}
