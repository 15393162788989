export const ReCaptchaV3Score = (() => {
  var called = false;
  var _siteKey;
  var _pid;

  var init = ({siteKey, pid}) => {
    if (called) return;
    called = true;

    _siteKey = siteKey;
    _pid = pid;

    var script = document.createElement('script');
    script.async = true;
    script.defer = true; // https://javascript.info/script-async-defer#dynamic-scripts
    script.setAttribute('async', 'async');
    script.setAttribute('defer', 'defer');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute(
      'src',
      'https://www.google.com/recaptcha/api.js?render=' + _siteKey
    );
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  var execute = function () {
    var callback = function () {
      return window.grecaptcha.execute(_siteKey, {action: _pid});
    };

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          resolve(callback()); // to avoid react sync error
        } catch (e) {
          reject(e);
        }
      }, 0);
    });
  };

  var isReady = function () {
    return window.grecaptcha && window.grecaptcha.execute;
  };

  return {init, isReady, execute};
})();