import React, {useContext, useState} from 'react';
import allSettled from 'promise.allsettled';
import * as R from 'ramda';
import * as yup from "yup";
import {FormHelperText, Grid, IconButton, makeStyles, Typography, CircularProgress, Box} from "@material-ui/core";
import {
  ArrowBack,
  EmailRounded,
  LocationCityRounded,
  PeopleRounded,
  PersonRounded,
  PhoneRounded
} from "@material-ui/icons";
import {Actions} from "../../config/wizard.context.config";
import {requestCode} from "../../verification";
import {submitContact} from "../../wizard";
import {run} from "../../validation";
import {ReCaptchaV3Score} from "../../boundaries/recaptcha.v3.score";
import {WizardContext} from "../../contexts/WizardContext";
import {LocaleContext} from "../../contexts/LocaleContext";
import {Form} from "../core/Form";
import {LabeledInput} from "../core/LabeledInput";
import {OutlinedButton} from "../core/OutlinedButton";
import {Layout} from "../layouts/Layout";
import {StepHeading} from "../layouts/StepHeading";
import {isCommercial} from "../../commercial";
import {submitCrossSelling} from "../../wizard";

import {captureException} from '@sentry/browser';
import {findClientRoute, findReferer, postMessage} from "../../message";
import {GH_WEBSITE} from "../../wizard.constants";
import staxPaymentLogo from "../../images/stax-payment-logo.svg";

const useStyles = makeStyles(theme => ({
  tcpa: {
    paddingBottom: '16px'
  },
  grid: {
    marginTop: '0px'
  },
  buttonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

export const ContactStep = ({vertical, questions, commercial: initialCommercial, mock }) => {
  const {
    translations: {
      contact: {title, sub_title, first_name, last_name, email, business_email, phone_number, company, tcpa, submit_button},
      verification: {request_code_error}
    },
    numbering_plan,
    pid: placement,
    country_iso_code: country,
    sessionId
  } = useContext(LocaleContext);
  const [{crossSelling, data: {answers}, data}, dispatch] = useContext(WizardContext);
  const commercial = isCommercial(crossSelling ?
    { questions: crossSelling.questions, answers: crossSelling.answers, commercial: crossSelling.commercial } :
    { questions, answers, commercial: initialCommercial },
  );
  const [sentSubmissionResult, setSubmissionResult] = useState(undefined);
  const classes = useStyles();
  const emailLabel = commercial ? business_email : email;
  const schema = {
    firstName: run(yup.string().required().min(2).max(50).label(first_name)),
    lastName: run(yup.string().required().min(2).max(50).label(last_name)),
    email: run(yup.string().required().email().emailAsync().label(emailLabel)),
    phoneNumber: run(yup.string().required().phone().label(phone_number)),
    companyName: run(yup.string().required().min(2).max(50).letterIncluded().label(company))
  };

  // TODO: DO NOT DELETE UNTIL business_loans QQ issues are solved.
  try {
    if (!answers) {
      captureException(new Error(`(TEST - NULL ANSWERS) ${vertical} - ` + JSON.stringify({sessionId, vertical, answers: null, title})));
    } else if (Object.keys(answers).length === 0) {
      captureException(new Error(`(TEST - EMPTY ANSWERS) ${vertical} - ` + JSON.stringify({sessionId, vertical, answers: 0, title})));
    }
  } catch (e) {
    captureException(e);
  }

  const onSubmitCrossSelling = async personal => {
    if (mock === true) {
      dispatch({ type: Actions.MOCKED_THANK_YOU, data: { mock }})
      return false;
    }

    const payload = { sessionId, placement: crossSelling.pid, answers: crossSelling.answers, personal };
    const { phoneNumber: originalPhoneNumber } = data;
    const { phoneNumber: phone } = personal;

    if (originalPhoneNumber === phone) {
      const { intentId } = await submitCrossSelling(payload);
      dispatch({type: Actions.GO_TO_THANK_YOU, payload: { intentId }});
      setSubmissionResult(true);
      return true;
    }

    const [{value: {type: codeType}}, {status, value: {intentId}}] = await allSettled([requestCode({sessionId, phone, country}), submitCrossSelling(payload)]);
    dispatch({type: Actions.SUBMIT_CROSS_SELLING_CONTACT, payload: { intentId, codeType, personal }})
    const valid = status === 'fulfilled';
    setSubmissionResult(valid);
    return valid;
  };

  const onSubmit = async response => {
    if (mock === true) {
      dispatch({ type: Actions.MOCKED_THANK_YOU, data: { mock }})
      return false;
    }

    const { data } = response;
    const { phoneNumber: phone } = data;
    const reCaptchaV3Token = ReCaptchaV3Score.isReady() ? await ReCaptchaV3Score.execute() : undefined;
    const requestData = {
      ...data,
      placement,
      country,
      vertical,
      sessionId,
      tcpa,
      referer: findReferer(),
      clientRoute: findClientRoute(),
      reCaptchaV3Token,
    };

    const [{value: {type}}, {status, value: {intentId}}] = await allSettled([requestCode({sessionId, phone, country}), submitContact(requestData)]);
    dispatch({type: Actions.UPDATE_CODE_TYPE, data: {codeType: type}});
    if (intentId) dispatch({type: Actions.UPDATE_INTENT_ID, intentId});
    const valid = status === 'fulfilled';
    setSubmissionResult(valid);
    if (valid) postMessage({ eventName: 'ghLead' });
    return valid;
  };

  return (
    <Layout
      title={title}
      icon={
        questions.length ?
        <IconButton
          edge="start"
          color="inherit"
          onClick={e => dispatch({type: Actions.STEP_BACK})}
        >
          <ArrowBack color="inherit"/>
        </IconButton> : <ArrowBack color="disabled"/>
      }
      vertical={vertical}
      hidePartneredLogo={true}
    >
      <StepHeading heading={sub_title}/>
      <Form
        isStepSubmitted={!crossSelling}
        initialValues={crossSelling ? R.prop('personal', crossSelling) : undefined}
        onSubmit={crossSelling ? onSubmitCrossSelling : onSubmit}
        render={({submitting, valid}) => (
          <>
            <Grid className={classes.grid} container spacing={2}>
              {commercial &&
              <Grid item xs={12}>
                <LabeledInput
                    name="companyName"
                    label={company}
                    validate={schema.companyName}
                    AdornmentIcon={LocationCityRounded}
                    autoComplete="your-company-name"
                    autoFocus
                />
              </Grid>
              }
              <Grid item xs={12} sm={6}>
                <LabeledInput
                  name="firstName"
                  label={first_name}
                  validate={schema.firstName}
                  AdornmentIcon={PersonRounded}
                  autoComplete="first-name"
                  autoFocus={!commercial}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LabeledInput
                  name="lastName"
                  label={last_name}
                  validate={schema.lastName}
                  AdornmentIcon={PeopleRounded}
                  autoComplete="family-name"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <LabeledInput
                  name="email"
                  type="email"
                  label={emailLabel}
                  validate={schema.email}
                  AdornmentIcon={EmailRounded}
                  autoComplete="email"
                  isAsync
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LabeledInput
                  name="phoneNumber"
                  type="tel"
                  label={phone_number}
                  validate={schema.phoneNumber}
                  format={numbering_plan.format}
                  AdornmentIcon={PhoneRounded}
                  autoComplete="tel-national"
                />
              </Grid>

            </Grid>

            {valid && sentSubmissionResult === false && <FormHelperText error>Unknown Error</FormHelperText>}

            <div className={classes.buttonWrapper}>
              <OutlinedButton disabled={submitting} label={submit_button} color="secondary"/>
              {submitting && <CircularProgress size={25} className={classes.buttonProgress} />}
            </div>

            <Typography
                align="justify"
                color="textSecondary"
                variant="caption"
                display="block"
                className={classes.tcpa}
            >
              <span dangerouslySetInnerHTML={{__html: tcpa}}/>
            </Typography>
          </>
        )}
      />

    </Layout>
  );
};
