import React, {useContext, useEffect} from 'react';
import {LocaleContext} from "../contexts/LocaleContext";
import {fetchLatLong} from "../validation";

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export const ThankYouMap = ({ postal }) => {
  const {country_iso_code: country} = useContext(LocaleContext);
  useEffect(() => {
    let remove = () => undefined;
    (async () => {
      const position = await fetchLatLong({postal, country});
      if (!position) return;
      remove = displayGoogleMap(position);
    })();
    return () => remove();
  },[]);

  const displayGoogleMap = (position) => {
    const script = window.document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&callback=initGhMap`;
    script.async = true;
    script.defer = true;
    script.id = 'ghGoogleMapScript';
    window.initGhMap = () => {
      const options = {
        zoom: 11,
        center: position,
        disableDefaultUI: true
      };
      const mapInternal = new window.google.maps.Map(document.getElementById('ghMap'), options);

      new window.google.maps.Marker({
        position: position,
        map: mapInternal
      });
    };

    window.document.body.appendChild(script);

    return () => {
      const script = window.document.getElementById('ghGoogleMapScript');
      if (script && script.remove) script.remove();
      window.initGhMap = undefined;
    };
  };

  return (
    <div id="ghMap" style={{ height: postal ? '160px' : 'inherit'}}/>
  )
};