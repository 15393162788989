import React from "react";
import { FormControlLabel, Radio, RadioGroup, makeStyles, Grid } from "@material-ui/core";
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    [theme.breakpoints.down('sm')] : {
      fontSize: '14px'
    },
    [theme.breakpoints.up('md')] : {
      fontSize: '20px'
    }
  }
}));

export const Select = ({value, options, onChange}) => {
  const classes = useStyles();

  return (
      <RadioGroup name={"RadioGroup"} onChange={onChange}>
        <Grid container>
          {options.map(({label, id}) => (
              <Grid item key={id} xs={12} sm={6} md={6} lg={3}>
                <FormControlLabel
                    className={`gh-wizard-label-root ${classes.formLabel}`}
                    checked={value === id}
                    value={id}
                    label={label}
                    control={<Radio/>}
                />
              </Grid>
          ))}
        </Grid>
      </RadioGroup>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};