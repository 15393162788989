import React, {Fragment, useContext} from 'react';
import * as R from 'ramda';
import {Button, Grid, List, ListItem, ListItemText, makeStyles, Paper, Typography,} from "@material-ui/core";
import {CheckCircle, Mail, Phone, Sms} from '@material-ui/icons';
import {LocaleContext} from "../../contexts/LocaleContext";
import {Layout} from "../layouts/Layout";
import {StepHeading} from "../layouts/StepHeading";
import {WizardContext} from "../../contexts/WizardContext";
import {WIZARD_PROXY_API} from "../../wizard.constants";
import {Rating} from '@material-ui/lab'
import {ThankYouMap} from "../ThankYouMap";
import {CrossSelling} from "../CrossSelling";
import Divider from "@material-ui/core/Divider";
import {useRemoteProviders} from "../../hooks/use.remote.provider";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '12px',
  },
  paper: {
    marginTop: theme.spacing(2)
  },
  list: {
    paddingTop: 'unset'
  },
  callToAction: {
    boxShadow: 'unset',
    '&:first-child': {
      marginRight: '8px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      padding: `12px`
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      padding: `16px`
    },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  },
  listItemButtons: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0.5)
    }
  },
  listHeadingItem: {
    background: theme.palette.primary.light,
    color: theme.palette.common.white,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px'
  },
  listItemTextPrimary: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.25rem',
    },
  }
}));

const RATINGS = [5, 4.5, 4.5, 4, 4, 3.5];

export const ThankYouStep = ({title: vertical_title_original, vertical, commercial}) => {
  const {sessionId, translations: {thank_you: {title, description}}} = useContext(LocaleContext);
  const [{data: {firstName, postal}, intentId, crossSelling}, _] = useContext(WizardContext);
  const providers = []; // useRemoteProviders({intentId: R.path(['intentId'], crossSelling) || intentId});
  const classes = useStyles();

  const verticalDisplayName = (R.path(['wizard', 'vertical_name'], crossSelling) || vertical).split("_").join(" ");
  const vertical_title = R.path(['wizard', 'qualifying_questions_title'], crossSelling) || vertical_title_original;
  const isBusinessFinancing = vertical === 'business_financing';
  const heading = "We have received your quote request successfully";
  return (
    <Layout title={title} icon={<CheckCircle color="inherit"/>} vertical={vertical}>
      <StepHeading heading={heading}/>
      {
        R.isEmpty(providers)
          ? (
            <Typography variant="body2" style={{marginTop: '8px', fontWeight: 'normal'}}>
              We've {providers.length ? 'found' : 'already begun to find'} the most suitable companies for you based
              on the requirements you submitted.
              Soon after, qualified providers will contact you directly with their quotes. We recommend talking
              with multiple providers to ensure you get the best offer possible. Please keep your phone ready as
              you'll be contacted shortly with competitive offers from our providers.
            </Typography>
          )
          : (
            <Paper className={classes.paper}>
              <List className={classes.list}>
                <ListItem className={classes.listHeadingItem}>
                  <ListItemText
                      primary={<Typography variant="h6">{vertical_title}</Typography>}
                      classes={{
                        primary: classes.listItemTextPrimary
                      }}
                  />
                </ListItem>
                <Divider variant="fullWidth" component="li"/>
                <ListItem style={{display: 'unset', padding: '0'}}>
                  <ThankYouMap postal={postal}/>
                </ListItem>
                <Divider variant="fullWidth" component="li"/>
                {providers.map(({company, phone, carrierType, email}, index) => (
                  <Fragment key={index}>
                    <ListItem className={classes.listItem}>
                      <div>
                        <Typography>{index + 1}. {company}</Typography>
                        <Rating defaultValue={RATINGS[index] ? RATINGS[index] : 3.5} precision={0.5} readOnly/>
                      </div>
                      <div className={classes.listItemButtons}>
                        <Grid container justify="flex-end" spacing={1}>
                          {phone && (
                            <Grid item>
                              <Button
                                variant="contained"
                                href={`tel:${phone}`}
                                size="large"
                                color="primary"
                                disableRipple
                                startIcon={<Phone/>}
                                className={classes.callToAction}>
                                Call
                              </Button>
                            </Grid>
                          )}
                          <Grid item>
                            <Button
                              variant="contained"
                              href={`mailto: ${email}?subject=Inquiry for ${verticalDisplayName}&body=Hello ${company}, %0d%0a%0d%0aI’m contacting you to get free ${verticalDisplayName} quotes. I was recommended to contact your company by GrowthHub. %0d%0a%0d%0aPlease let me know dates %26 times you are available for a quick call. %0d%0a%0d%0aThanks, %0d%0a%0d%0a${firstName}`}
                              size="large"
                              color="primary"
                              disableRipple
                              startIcon={<Mail/>}
                              className={classes.callToAction}>
                              Email
                            </Button>
                          </Grid>
                          {carrierType === 'MOBILE' && (
                            <Grid key={index} item>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  window.open(`${WIZARD_PROXY_API}/tracking/click?source=wizard&sourceType=web&linkType=sms&trackingId=${sessionId}&to=${phone}&body=Hello ${company}, please reach out to me about the ${verticalDisplayName} options and pricing you have to offer. Thank you.`)
                                }}
                                size="large" color="primary"
                                disableRipple
                                startIcon={<Sms/>}
                                className={classes.callToAction}>
                                Text
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    </ListItem>
                    {
                      providers.length - 1 > index && (
                        <Divider variant="fullWidth" component="li"/>
                      )
                    }
                  </Fragment>
                ))}
              </List>
            </Paper>
          )
      }
      { commercial && <CrossSelling vertical={vertical}/> }
    </Layout>
  )
};