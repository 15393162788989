import React from 'react';
import {FormHelperText} from "@material-ui/core";
import PropTypes from 'prop-types';

export const HelperMessage = ({touched, error, text}) => (touched && error) || !text ? null : (
  <FormHelperText style={{ marginLeft: '2px'}}>{text}</FormHelperText>
);

HelperMessage.propTypes = {
  touched: PropTypes.bool,
  error: PropTypes.string,
  text: PropTypes.string,
};