// Internet Explorer 11 support
import 'idempotent-babel-polyfill';
import 'isomorphic-fetch';

import * as rrweb from 'rrweb';
import * as R from 'ramda';
import React from 'react';
import ReactDOM from 'react-dom';
import queryString from 'query-string'
import App from './App';
import {withStyles} from "@material-ui/core/styles";
import * as Sentry from '@sentry/browser';

import { eventData } from './event';
import {initializeHeightListener, initializeMessage, postMessage} from "./message";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_RELEASE_VERSION
});
// const isDev = process.env.NODE_ENV === 'development';

const GlobalStyles = withStyles({
  "@global": {
    "body .grecaptcha-badge": {
      display: 'none !important;'
    }
  }
})(() => <></>);

rrweb.record({
  emit(event) {
    eventData.events.push(event);
    eventData.eventIndex++;
  }
});

const GHWizardApp = {
  init: (options = {}) => {
    const {query} = queryString.parseUrl(global.location.href);
    const extendedOptions = { pid: query.pid, ...(query.options ? JSON.parse(query.options) : options), };
    const updatedOptions = extendedOptions.pid ? R.over(R.lensProp('pid'), R.trim, extendedOptions) : extendedOptions;
    const {pid, tracking} = updatedOptions;
    const containerId = 'root';
    initializeMessage({tracking});
    initializeHeightListener({containerId});
    postMessage({ action: 'DIVOLTE_EVENT', actionType: 'WizardLoaded', actionPayload: { pid } });
    ReactDOM.render(<><App options={updatedOptions}/><GlobalStyles/></>,
      document.getElementById(containerId)
    )
  }
};

GHWizardApp.init();

// global.GHWizardApp = GHWizardApp;