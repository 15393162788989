import VerticalSplitOutlinedIcon from "@material-ui/icons/VerticalSplitOutlined";
import HeadsetMicOutlinedIcon from "@material-ui/icons/HeadsetMicOutlined";
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import { AttachMoney } from "@material-ui/icons";
import {
  BUSINESS_PHONE_SYSTEMS_PLACEMENT,
  WEBSITE_DESIGN_PLACEMENT,
  CREDIT_CARD_PROCESSING_PLACEMENT,
  POS_SYSTEMS_PLACEMENT,
  BUSINESS_LOANS_PLACEMENT
} from "./wizard.constants";

export const CROSS_SELLING_VERTICALS = [
  {
    vertical: "website_design",
    title: "Website Design",
    body: "Do you have a website? Professional web design starts from $199. Bring the world to your website.",
    icon: VerticalSplitOutlinedIcon,
    pid: WEBSITE_DESIGN_PLACEMENT
  },
  {
    vertical: "business_loans",
    title: "Business Loans",
    body: "We’ll help you access financing for your business, reach your goals and thrive. Get best quotes to support your cash flow and meet your business goals",
    icon: AttachMoney,
    pid: BUSINESS_LOANS_PLACEMENT,
  },
  {
    vertical: "pos_systems",
    title: "POS Systems",
    body: "The best POS systems will make your business more efficient – and your life easier – by maximising your profits.",
    icon: ReceiptOutlinedIcon,
    pid: POS_SYSTEMS_PLACEMENT,
  },
  {
    vertical: "credit_card_processing",
    title: "Credit Card Processing",
    body: "Make lightening fast and most reliable transactions with lowest fees for your business.",
    icon: CreditCardOutlinedIcon,
    pid: CREDIT_CARD_PROCESSING_PLACEMENT,
  },
  {
    vertical: "business_phone_systems",
    title: "Business Phone Systems",
    body: "Time for a new phone system? First month free trial that you can cancel anytime. WiFi calling worldwide.",
    icon: HeadsetMicOutlinedIcon,
    pid: BUSINESS_PHONE_SYSTEMS_PLACEMENT,
    url: 'https://www.comparison411.com/voip/rankings/',
  },
]
