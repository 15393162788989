import {useEffect, useState} from 'react';
import * as R from 'ramda';
import {captureException} from "@sentry/browser";
import {fetchSchema} from "../wizard";

export const useRemoteSchema = ({pid}) => {
  const [response, setResponse] = useState();
  const [first, setFirst] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        if (first) {
          if (pid) setResponse(await fetchSchema({pid}));
          else setResponse({data: {wizard: {qualifying_questions: []}}});
          setFirst(false);
          return;
        }
      } catch (e) {
        console.error(e);
        return;
      }

      // TODO: DO NOT DELETE UNTIL duplicated leads issues are completely solved.
      try {
        if (!first) {
          const vertical_name = R.path(['data', 'wizard', 'vertical_name'], response);
          const sessionId = R.path(['sessionId'], response);
          captureException(new Error(`(TEST - Leads Duplicated) ${vertical_name} - ` + JSON.stringify({sessionId, pid, first})));
        }
      } catch (e) {
        captureException(e);
      }
    })();
  }, [pid]);

  return response;
};