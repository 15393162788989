import React from "react";
import {Select} from "./Select";
import {ErrorMessage} from "./ErrorMessage";
import PropTypes from 'prop-types';
import {InputDialog} from "./InputDialog";

export const DialogSelect = ({
 change,
 onChange,
 input,
 meta: {active, touched, error},
 label,
 options,
    questionNumber
}) => {
  const touchedError = touched && !!error;
  const {value, name} = input;
  const getSecondary = value => value
    ? options.filter(({id}) => id === value)[0].label
    : touchedError
      ? <ErrorMessage touched={touched} error={error}/>
      : 'Click Here to Select';
  return (
    <InputDialog
      label={label}
      secondary={getSecondary(value)}
      instant={true}
      questionNumber={questionNumber}
      render={({setSecondary, setOpen}) => {
        return (
          <Select
            {...input}
            options={options}
            onChange={e => {
              const value = e.target.value;
              setSecondary(getSecondary(value));
              change(name, value);
              setOpen(false);
              if (onChange) onChange(e, {name, value});
            }}
          />
        );
      }}/>
  )
};

DialogSelect.propTypes = {
  change: PropTypes.func.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    checked: PropTypes.bool,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};