import React, {Fragment, useContext, useState} from "react";
import {
  Button,
  CircularProgress, Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";
import {WizardContext} from "../contexts/WizardContext";
import {Actions} from "../config/wizard.context.config";
import Divider from "@material-ui/core/Divider";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import {CROSS_SELLING_VERTICALS} from "../cross.selling.constants";
import * as R from 'ramda';
import {fetchSchema} from "../wizard";
import {LocaleContext} from "../contexts/LocaleContext";
import {postMessage} from "../message";
import * as queryString from "querystring";

const useStylesCard = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3)
  },
  list: {
    paddingTop: 'unset'
  },
  button: {
    boxShadow: 'unset',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: '14px',
      padding: `12px`
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      padding: `16px`
    },
  },
  listItemText: {
    flex: 1
  },
  listItemTextBody: {
    paddingRight: theme.spacing(2.5),
    color: `rgba(0, 0, 0, 0.54)`,
    fontSize: '0.875rem'
  },
  listHeadingItem: {
    background: theme.palette.primary.light,
    color: theme.palette.common.white,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px'
  },
  listItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  },
  listItemTextPrimary: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.25rem',
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(7)
  }
}));

export const CrossSelling = ({vertical}) => {
  const classes = useStylesCard();
  const [{excludedVerticals}, dispatch] = useContext(WizardContext);
  const { pid } = useContext(LocaleContext);
  const [crossSellingLoading, setCrossSellingLoading] = useState(false);
  const internalExcludedVerticals = R.uniq([...excludedVerticals, vertical]);
  const crossSellingVerticals = R.differenceWith(
    (item1, item2) => item1.vertical === item2,
    CROSS_SELLING_VERTICALS,
    internalExcludedVerticals
  );
  const maxIndex = crossSellingVerticals.length - 1;

  const handleOnClick =  ({ crossSalesPid, vertical: crossSellingVertical }) => {
    (async () => {
      setCrossSellingLoading(true);
      postMessage({
        action: 'DIVOLTE_EVENT',
        actionType: 'WizardLoaded',
        actionPayload: { pid, crossSalesPid }
      });
      const WIZARD = ['data', 'wizard'];
      const response = await fetchSchema({pid: crossSalesPid});
      const wizard = R.path(WIZARD, response);

      dispatch({
        type: Actions.START_CROSS_SELLING,
        payload: {
          pid: crossSalesPid,
          excludedVerticals: R.uniq([...internalExcludedVerticals, crossSellingVertical]),
          wizard
        }
      });

      setCrossSellingLoading(false);
    })();
  }

  if (R.isEmpty(crossSellingVerticals)) return <Fragment/>;

  const { pid: privatePid, ...queryParams } = queryString.parse(window.location.search.replace('?', ''));

  return crossSellingLoading ? <div className={classes.loading}> <CircularProgress color="inherit"/> </div> : (
    <Paper className={classes.paper}>
      <List className={classes.list}>
        <ListItem className={classes.listHeadingItem}>
          <ListItemText
              primary={<Typography variant="h6">Get Free Quotes to Grow Your Business</Typography>}
              classes={{
                primary: classes.listItemTextPrimary
              }}
          />
        </ListItem>
        <Divider variant="fullWidth" component="li"/>
        {crossSellingVerticals.map(({icon: Icon, title, body, pid: crossSalesPid, vertical, url }, index) => (
          <Fragment key={index}>
            <ListItem {
              ...url
                ? {
                  component: Link,
                  href: url,
                  target: "_blank",
                  style: {textDecoration: 'none', color: 'inherit'}
                }
                : {
                  onClick: () => handleOnClick({crossSalesPid, vertical})
                }
            }>
              <ListItemIcon>
                <Icon color="primary" fontSize="large"/>
              </ListItemIcon>
              <div className={classes.listItemContainer}>
                <div className={classes.listItemText}>
                  <Typography>{title}</Typography>
                  <Typography className={classes.listItemTextBody}>
                    {body}
                  </Typography>
                </div>
                {
                  url ? (
                      <Button
                          color="primary"
                          size="large"
                          variant="contained"
                          className={classes.button}
                          startIcon={<DescriptionOutlinedIcon/>}
                          as="a"
                          href={`${url}${queryString.stringify(queryParams) ? `&${queryString.stringify(queryParams)}` : ''}`}
                          target="_blank"
                      >
                        Get Pricing
                      </Button>
                  ) : (
                      <Button
                          color="primary"
                          size="large"
                          variant="contained"
                          className={classes.button}
                          startIcon={<DescriptionOutlinedIcon/>}
                          onClick={() => handleOnClick({ crossSalesPid, vertical })}
                      >
                        Get Pricing
                      </Button>
                  )
                }
              </div>
            </ListItem>
            {maxIndex > index && (
              <Divider variant="fullWidth" component="li"/>
            )}
          </Fragment>
        ))}
      </List>
    </Paper>
  );
};