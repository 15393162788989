import * as R from 'ramda';
import reduceReducers from "reduce-reducers";

export const initialState = {
  wizardOpen: false,
  postalOpen: true,
  data: {answers: {}},
  questions: [],
  first: true,
  intentId: null,
  crossSelling: null,
  excludedVerticals: [],
};

const initialCrossSelling = {
  pid: undefined,
  vertical: undefined,
  verticalDisplayName: undefined,
  commercial: undefined,
  questions: [],
  answers: {},
  personal: {},
  intentId: undefined,
  codeType: undefined,
};

export const Actions = {
  ON_LOAD: 'ON_LOAD',
  STEP_BACK: 'STEP_BACK',
  SUBMIT_POSTAL: 'SUBMIT_POSTAL',
  SUBMIT_QUESTION: 'SUBMIT_QUESTION',
  ON_COMPLETE_GOOGLE_MAP: 'ON_COMPLETE_GOOGLE_MAP',
  SUBMIT_CONTACT: 'SUBMIT_CONTACT',
  VERIFY_CODE: 'VERIFY_CODE',
  UPDATE_PHONE_NUMBER: 'UPDATE_PHONE_NUMBER',
  UPDATE_CODE_TYPE: 'UPDATE_CODE_TYPE',
  UPDATE_INTENT_ID: 'UPDATE_INTENT_ID',
  OPEN_WIZARD_POPUP: 'OPEN_WIZARD_POPUP',
  CLOSE_WIZARD_POPUP: 'CLOSE_WIZARD_POPUP',

  START_CROSS_SELLING: 'START_CROSS_SELLING',
  SUBMIT_CROSS_SELLING_QUESTION: 'SUBMIT_CROSS_SELLING_QUESTION',
  SUBMIT_CROSS_SELLING_CONTACT: 'SUBMIT_CROSS_SELLING_CONTACT',
  UPDATE_CROSS_SELLING_PHONE_NUMBER: 'UPDATE_CROSS_SELLING_PHONE_NUMBER',
  GO_TO_THANK_YOU: 'GO_TO_THANK_YOU',
  MOCKED_THANK_YOU: 'MOCKED_THANK_YOU'
};

export const Steps = {
  QUESTION_POSTAL: {
    name: 'POSTAL'
  },
  QUESTION: {
    name: 'QUESTION',
    action: Actions.SUBMIT_QUESTION
  },
  GOOGLE_MAP: {
    name: 'GOOGLE_MAP'
  },
  CONTACT: {
    name: 'CONTACT',
    action: Actions.SUBMIT_CONTACT
  },
  VERIFICATION_STEP: {
    name: 'VERIFICATION_STEP',
    action: Actions.VERIFY_CODE
  },
  THANK_YOU: {
    name: 'THANK_YOU'
  }
};

const rootReducer = (state, {type, data: initialData = {}, questions, intentId}) => {
  const data = {...state.data, ...initialData};
  switch (type) {
    case Actions.STEP_BACK:
      return {
        ...state,
        first: false,
        step: Steps.QUESTION
      };
    case Actions.ON_LOAD:
      return {
        ...state,
        data,
        questions,
        step: Steps.QUESTION_POSTAL
      };
    case Actions.SUBMIT_POSTAL:
      return {
        ...state,
        data,
        postalOpen: false,
        wizardOpen: true,
        step: state.questions.length ? Steps.QUESTION : Steps.GOOGLE_MAP
      };
    case Actions.SUBMIT_QUESTION:
      const answers = {...state.data.answers, ...initialData.answers};
      return {
        ...state,
        data: {
          ...state.data,
          answers
        },
        step: Steps.GOOGLE_MAP
      };
    case Actions.ON_COMPLETE_GOOGLE_MAP:
      return {
        ...state,
        postalSearched: true,
        step: Steps.CONTACT,
      };
    case Actions.SUBMIT_CONTACT:
      return {
        ...state,
        data,
        step: Steps.VERIFICATION_STEP
      };
    case Actions.VERIFY_CODE:
      return {...state, step: Steps.THANK_YOU};
    case Actions.UPDATE_PHONE_NUMBER:
      return {...state, data};
    case Actions.UPDATE_CODE_TYPE:
      return {...state, data};
    case Actions.UPDATE_INTENT_ID:
      return {...state, intentId};
    case Actions.OPEN_WIZARD_POPUP:
      return {...state, wizardOpen: true}
    case Actions.CLOSE_WIZARD_POPUP:
      return {...state, wizardOpen: false}
    case Actions.MOCKED_THANK_YOU:
      return { ...state, step: Steps.THANK_YOU };
    default:
      return state;
  }
};

const crossSellingReducer = (state, {type, payload }) => {
  const stateCrossSelling = R.prop('crossSelling', state);
  switch (type) {
    case Actions.START_CROSS_SELLING:
      const { data: { firstName, lastName, email, phoneNumber, companyName, } } = state;
      const personal = { firstName, lastName, email, phoneNumber, companyName, };
      const { pid, wizard, excludedVerticals } = payload;
      const {
        qualifying_questions: questions = [],
        vertical_name: vertical,
        commercial,
        qualifying_questions_title: _,
        vertical_display_name: verticalDisplayName
      } = wizard;
      return {
        ...state,
        crossSelling: { ...initialCrossSelling, pid, personal, questions, vertical, verticalDisplayName, commercial, },
        excludedVerticals,
        step: Steps.QUESTION
      };
    case Actions.SUBMIT_CROSS_SELLING_QUESTION:
      const answers = { ...stateCrossSelling.answers, ...payload.answers };
      return { ...state, crossSelling: { ...stateCrossSelling, answers }, step: Steps.GOOGLE_MAP };
    case Actions.SUBMIT_CROSS_SELLING_CONTACT:
      return {
        ...state,
        crossSelling: {
          ...stateCrossSelling,
          intentId: payload.intentId,
          codeType: payload.codeType,
          personal: payload.personal,
        },
        step: Steps.VERIFICATION_STEP
      };
    case Actions.UPDATE_CROSS_SELLING_PHONE_NUMBER:
      const personalUpdated = {...stateCrossSelling.personal, phoneNumber: payload.phoneNumber};
      return {...state, crossSelling: {...stateCrossSelling, codeType: payload.codeType, personal: personalUpdated}};
    case Actions.GO_TO_THANK_YOU:
      return {...state, crossSelling: {...stateCrossSelling, intentId: payload.intentId}, step: Steps.THANK_YOU};
    case Actions.MOCKED_THANK_YOU:
      return { ...state, step: Steps.THANK_YOU };
    default:
      return state;
  }
};

export const reducer = reduceReducers(rootReducer, crossSellingReducer);