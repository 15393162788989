import React, {useState} from "react";
import {Button, makeStyles} from "@material-ui/core";
import {ErrorMessage} from "./ErrorMessage";
import PropTypes from 'prop-types';
import {LabeledInput} from "./LabeledInput";
import {InputDialog} from "./InputDialog";

const useStyles = makeStyles(them => ({
  root: {
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  button: {
    marginTop: '12px',
    marginBottom: '12px'
  }
}));

export const DialogTextField = ({
  label,
  input,
  meta: {active, touched, error},
  change,
  validate,
  format,
  AdornmentIcon,
  labelButton,
  ...initialProps
}) => {
  const classes = useStyles();
  const [entered, setEntered] = useState(undefined);

  const touchedError = touched && !!error;
  const {value, name} = input;
  const secondary = value || (touchedError
    ? <ErrorMessage touched={touched} error={error}/>
    : 'Click Here to Select');

  return (
    <InputDialog
      label={label}
      secondary={secondary}
      classes={classes}
      render={({setSecondary, setOpen}) => {
        const onSubmit = e => {
          if (!error) {
            setSecondary(value);
            setOpen(false);
          }
        };
        const onKeyPress = e => {
          const key = e.keyCode || e.which;
          if (key === 13) {
            setEntered(true);
            e.preventDefault();
            onSubmit(e);
          }
        };

        return (
          <>
            <LabeledInput
              {...initialProps}
              onKeyPress={onKeyPress}
              name={name}
              label={label}
              validate={validate}
              format={format}
              AdornmentIcon={AdornmentIcon}
              entered={entered}
            />
            <Button
              onClick={onSubmit}
              fullWidth
              size="large"
              color="secondary"
              variant="contained"
              type="button"
              className={classes.button}
            >
              {label}
            </Button>
          </>
        );
    }}/>
  )
};

DialogTextField.propTypes = {
  change: PropTypes.func.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    checked: PropTypes.bool,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};