import React, {useContext, useState} from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {VerifiedUser} from "@material-ui/icons";
import {LocaleContext} from "../../contexts/LocaleContext";
import {WizardContext} from "../../contexts/WizardContext";
import {Layout} from "../layouts/Layout";
import {StepHeading} from "../layouts/StepHeading";
import {VerificationCode} from "../VerificationCode";
import {RequestCode} from "../RequestCode";
import * as R from "ramda";

const useStyles = makeStyles(theme => ({
  typography: {
    paddingTop: '8px',
  },
  list: {
    marginTop: '12px',
    borderRadius: '8px',
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  button: {
    marginTop: '12px',
    marginBottom: '12px'
  }
}));

export const VerificationStep = ({ vertical }) => {
  const classes = useStyles();
  const [sent, setSent] = useState(true);
  const [{crossSelling, data: {phoneNumber: phoneFromData, codeType: codeTypeFromData}}, _] = useContext(WizardContext);
  const phoneNumber = crossSelling ? R.path(['personal', 'phoneNumber'], crossSelling) : phoneFromData;
  const codeType = crossSelling ? R.path(['codeType'], crossSelling) : codeTypeFromData;
  const {numbering_plan: {country_code}, translations: {verification: {title, sub_title, description}}} = useContext(LocaleContext);
  return (
    <Layout title={title} icon={<VerifiedUser color="inherit"/>} vertical={vertical}>
      <StepHeading heading={sub_title}/>
      <Typography
        display="block"
        variant="caption"
        color="textSecondary"
        className={classes.typography}
      >
        {sent && description.replace(/\${(\w*?)}/, `${country_code} ${phoneNumber}`).replace(/\${(\w*?)}/, codeType)}
      </Typography>
      { sent ? <VerificationCode setSent={setSent} /> : <RequestCode sent={sent} setSent={setSent}/> }
    </Layout>
  );
};