import React from "react";
import {Button, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontSize: '18px',
    padding: `12px`
  }
}));

export const OutlinedButton = ({label, color = 'primary', ...rest}) => {
  const classes = useStyles();
  return (
    <Button
      fullWidth
      size="large"
      color={color}
      variant="contained"
      type="submit"
      className={classes.root}
      {...rest}
    >
      <span>{label}</span>
    </Button>
  );
};
