import React, {useReducer} from "react";
import queryString from 'query-string';
import { parse } from 'tldts';
import {createGenerateClassName, StylesProvider, ThemeProvider} from "@material-ui/styles";
import {registerYupConfig} from "./config/yup.config";
import {StepContainer} from "./components/StepContainer";
import {createMuiTheme} from "@material-ui/core";
import {blue, grey, red} from "@material-ui/core/colors";
import {useRemoteSchema} from "./hooks/use.remote.schema";
import {WizardContextProvider} from "./contexts/WizardContext";
import {LocaleContextProvider} from "./contexts/LocaleContext";
import {reducer, initialState} from "./config/wizard.context.config";
import PropTypes from "prop-types";
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import {REPLAY_API} from "./wizard.constants";
import { eventData } from './event';
import {findClientRoute, findLocationSearch, findReferer, postMessage} from "./message";
// import response from './data/wizard'

const generatedClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: 'gh-wizard-'
});

const generateTheme = options => {
  const {palette = {}} = options;
  return createMuiTheme({
    palette: {
      primary: palette.primary || blue,
      secondary: palette.secondary || red,
      default: palette.general || grey,
      text: palette.text || {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)"
      }
    }
  });
};

const App = ({ options }) => {
  const { hostname } = parse(findClientRoute());
  const titleChanged = hostname.includes('wizard.growthhub.com');
  const useWizardState = useReducer(reducer, initialState);
  const response = useRemoteSchema({pid: options.pid});
  if (!response) return null;
  const {data: {wizard: {locale: localeRegion, commercial, qualifying_questions_title, vertical_name }}, sessionId} = response;
  recordEvents(sessionId);
  postMessage({ action: 'UPDATE_VERTICAL', verticalName: vertical_name });
  const {country: isoCountryCode} = queryString.parse(findLocationSearch());
  const countries = ['US', 'CA', 'AU', 'GB', 'IE', 'NZ'];
  const localeRegionResolved = R.includes(localeRegion, countries.map(v => `en_${v}`)) ?
    (isoCountryCode && countries.includes(isoCountryCode.toUpperCase()) ? `en_${isoCountryCode.toUpperCase()}` : localeRegion) :
    'en_US';
  const locale_en_US = {...require(`./locales/en_US.json`), sessionId, pid: options.pid, options};
  const locale = localeRegionResolved === 'en_US' ? locale_en_US : R.mergeDeepRight(locale_en_US, require(`./locales/${localeRegionResolved}.json`));
  const {numbering_plan, postal, country_iso_code, translations: {validation}} = locale;

  registerYupConfig({locale: {numbering_plan, postal, country_iso_code}, validation});

  return (
    <>
      {
        titleChanged && <Helmet>
          <title>{qualifying_questions_title} - GrowthHub</title>
        </Helmet>
      }
      <StylesProvider generateClassName={generatedClassName}>
        <WizardContextProvider value={useWizardState}>
          <LocaleContextProvider value={locale}>
            <ThemeProvider theme={generateTheme(options)}>
              <StepContainer wizard={{...response.data.wizard, pid: options.pid, commercial, mock: options.mock ? options.mock : false }} sessionId={sessionId} />
            </ThemeProvider>
          </LocaleContextProvider>
        </WizardContextProvider>
      </StylesProvider>
    </>
  )
};


export const recordEvents = (visitorId) => {
  const save = () => {
    const size = eventData.events.length;
    if (size === 0) return setTimeout(save, 1000);
    const body = JSON.stringify({
      events: eventData.events,
      referer: findReferer(),
      clientRoute: findClientRoute(),
    });
    const timestamp = eventData.events[0].timestamp;
    eventData.events = [];
    (async () => {
      try {
        await fetch(`${REPLAY_API}?timestamp=${timestamp}&size=${size}&index=${eventData.eventIndex}&visitorId=${visitorId}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', },
          body, //: JSON.stringify({encoded}),
        })
      } catch (e) {
        console.error('Failed to call replay API', e);
      }
      setTimeout(save, 1000);
    })();
  };
  setTimeout(save, 0);
};

App.propTypes = {
  options: PropTypes.shape({
    pid: PropTypes.string.isRequired,
    splashImage: PropTypes.bool,
  }).isRequired
};

export default App;